import { Components } from '@mui/material';

export const MuiMenu: Components = {
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: '0px'
      }
    }
  }
};
