import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SHOW_DETAIL: true
};

export const shipmentDetailSlice = createSlice({
  name: 'shipmentDetail',
  initialState,
  reducers: {
    setShowDetail: (state, action) => {
      state.SHOW_DETAIL = action.payload;
    }
  }
});

export const { setShowDetail } = shipmentDetailSlice.actions;
export default shipmentDetailSlice.reducer;
