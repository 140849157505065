import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './config';
const URL = import.meta.env.VITE_AUTH_API_URL;

export const authApiService = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery(URL),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [
    'Auth',
    'Post',
    'Project',
    'ProjectSelection',
    'ProjectUsers',
    'UserProfile',
    'CompanyInformation',
    'UserManagement',
    'UserRoleList',
    'AddressManagement',
    'Vehicle',
    'VehicleLicensePlate',
    'Driver',
    'VehicleTypeTaxonomy',
    'VehicleTrailerTypeTaxonomy',
    'VehicleWeightTaxonomy'
  ],
  endpoints: () => ({})
});
