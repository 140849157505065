import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'react-use-cookie';

export enum AuthStatus {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT'
}

export interface User {
  id: string;
  name: string;
  email: string;
  token: {
    accessToken: {
      jwt: string;
      expiresIn: number | string;
    };
    refreshToken: {
      jwt: string;
      expiresIn: number | string;
    };
  };
}

interface AuthState {
  AUTH_STATUS: AuthStatus;
  USER: User | null;
  USER_COOKIE: string | null;
}

const initialState = {
  AUTH_STATUS: AuthStatus.LOADING,
  USER: null,
  USER_COOKIE: getCookie('user')
} satisfies AuthState as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOGGED_IN: (state, action) => {
      state.AUTH_STATUS = AuthStatus.LOGGED_IN;
      state.USER = action.payload;
      setCookie('user', JSON.stringify(action.payload));
    },
    LOGGED_OUT: (state) => {
      state.AUTH_STATUS = AuthStatus.LOGGED_OUT;
      state.USER = null;
      state.USER_COOKIE = '';
      setCookie('user', '');
      localStorage.removeItem('isTokenSent');
      localStorage.setItem('selectedProject', '');
    },
    SET_USER_COOKIE: (state, action) => {
      setCookie('user', JSON.stringify(action.payload));
      state.USER_COOKIE = JSON.stringify(action.payload);
    }
  }
});

export const { LOGGED_IN, LOGGED_OUT, SET_USER_COOKIE } = authSlice.actions;
export default authSlice.reducer;
