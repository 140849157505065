// MAIN

const MAIN_PATH = '/';

// AUTHENTICATION

const LOGIN_PATH = '/auth/login/:email?/:emailOtp?';
const REGISTER_PATH = '/auth/register';
const RESET_PASSWORD_PATH = '/auth/reset-password/:otp';
const FORGOT_PASSWORD_PATH = '/auth/forgot-password';
const RESET_PASSWORD_SAVE_PATH = '/reset-password-save';
const RESET_PASSWORD_SUCCESSFUL_PATH = '/reset-password-successful';
const GSM_VERIFY_PATH = '/gsm-verify';
const EMAIL_VERIFY_PATH = '/email-verify';
const GSM_CHANGE_PATH = '/gsm-change';
const INVITED_USER_REGISTER_PATH = '/invited-user-register';
const INVITED_USER_CHANGE_GSM_PATH = '/invited-user-change-gsm';
const INVITED_USER_VERIFY_GSM_PATH = '/invited-user-verify-gsm';
const INVITED_USER_SUCCESSFUL_PATH = '/invited-user-successful';

// DASHBOARD

// ShipmentManagement
const SHIPMENT_MANAGEMENT_PATH = '/';
const FTL_ADD_PATH = '/shipment-management/ftl-add/:id?';
const FTL_PREVIEW_PATH = '/shipment-management/ftl-preview';
const CONTAINER_ADD_PATH = '/shipment-management/container-add';
const CONTAINER_PREVIEW_PATH = '/shipment-management/container-preview';
const OTHER_ADD_PATH = '/shipment-management/other-add';
const SHIPMENT_MANAGEMENT_TRANSPORT_REQUESTS_DETAIL_PATH = '/shipment-management/transport-requests-detail';
const SHIPMENT_MANAGEMENT_TRANSPORTS_ROAD_DETAIL_PATH = '/shipment-management/transports-road-detail';
const SHIPMENT_MANAGEMENT_DRIVER_APPROVAL_DETAIL_PATH = '/shipment-management/driver-approval-detail';
const SHIPMENT_MANAGEMENT_DISPATCH_STAGE_DETAIL_PATH = '/shipment-management/dispatch-stage-detail';
const SHIPMENT_MANAGEMENT_DELIVERY_STAGE_DETAIL_PATH = '/shipment-management/delivery-stage-detail';
const SHIPMENT_MANAGEMENT_DELIVERY_APPROVAL_DETAIL_PATH = '/shipment-management/delivery_approval-detail';
const SHIPMENT_MANAGEMENT_LOADING_STAGE_DETAIL_PATH = '/shipment-management/loading-stage-detail';

// CompletedShipments
const SHIPMENT_COMPLETED_PATH = '/completed-shipments';
const SHIPMENT_COMPLETED_DETAIL_PATH = '/completed-shipments/detail';
const SHIPMENT_LOAD_PREVIEW_PATH = '/completed-shipments/load-preview';
const SHIPMENT_LOAD_ADD_PATH = '/completed-shipments/load-add';

// RampManagement
const RAMP_PATH = '/ramp-management';
const RAMP_ADD_PATH = '/ramp-management/add';
const RAMP_EDIT_PATH = '/ramp-management/edit';

// CompanyManagement
// CompanyInformation
const COMPANY_INFORMATION_PATH = '/company-info';
const COMPANY_INFORMATION_ADD_PATH = '/company-info/add-info';
const COMPANY_INFORMATION_DELETE_ACCOUNT_PATH = '/company/information/delete-account';
const COMPANY_INFORMATION_DELETE_ACCOUNT_CONFIRM_PATH = '/company/information/delete-account-confirm';
// Project
const COMPANY_PROJECT_PATH = '/company/project';
const COMPANY_PROJECT_ADD_PATH = '/company/project/add';
const COMPANY_PROJECT_EDIT_PATH = '/company/project/edit';
const COMPANY_PROJECT_ASSIGN_USER_PATH = '/company/project/assign-user';
const COMPANY_PROJECT_DETAIL_PATH = '/company/project/detail';
// Supplier
const COMPANY_SUPPLIER_PATH = '/company/supplier';
const COMPANY_SUPPLIER_ADD_PATH = '/company/supplier/add';
const COMPANY_SUPPLIER_EDIT_PATH = '/company/supplier/edit';
const COMPANY_SUPPLIER_ASSIGN_USER_PATH = '/company/supplier/assign-user';
// Customer
const COMPANY_CUSTOMER_PATH = '/company/customer';
const COMPANY_CUSTOMER_ADD_PATH = '/company/customer/add';
const COMPANY_CUSTOMER_EDIT_PATH = '/company/customer/edit';
const COMPANY_CUSTOMER_DETAIL_PATH = '/company/customer/detail';
// Address
const COMPANY_ADDRESS_PATH = '/company/address';
const COMPANY_ADDRESS_ADD_PATH = '/company/address/add';
const COMPANY_ADDRESS_EDIT_PATH = '/company/address/edit';
const COMPANY_ADDRESS_DETAIL_PATH = '/company/address/detail';

// UserManagement
const USER_PATH = '/usermanagement';
const USER_ADD_PATH = '/usermanagement/add';
const USER_DETAIL_PATH = '/usermanagement/detail';

// Inventory
// Vehicle
const INVENTORY_VEHICLE_PATH = '/inventory/vehicle';
const INVENTORY_VEHICLE_ADD_PATH = '/inventory/vehicle/add/:id?';
const INVENTORY_VEHICLE_EDIT_PATH = '/inventory/vehicle/edit';
const INVENTORY_VEHICLE_ASSIGNMENT_PATH = '/inventory/vehicle/assignment';
const INVENTORY_VEHICLE_DETAIL_PATH = '/inventory/vehicle/detail';
// Driver
const INVENTORY_DRIVER_PATH = '/inventory/driver';
const INVENTORY_DRIVER_ADD_PATH = '/inventory/driver/add';
const INVENTORY_DRIVER_DETAIL_PATH = '/inventory/driver/detail/:driverId?';
const INVENTORY_DRIVER_DETAIL_ASSIGNMENT_PATH = '/inventory/driver/detail-assignment';
// BillManagement
const BILL_PATH = '/bill-management';
const BILL_DETAIL_PATH = '/bill-management/detail';
// CompletedTransports
const COMPLETED_PATH = '/completed-transportations';

// Shipment Detail
const SHIPMENT_DETAIL = '/shipment-detail/:id';

// Shipment Detail UnAuthorized Live Track Link
const SHIPMENT_TRACK_LINK_PATH = '/shipment/track/link';

// Error Page
const ERROR_PATH = '/error';

export const ROUTES = {
  INDEX: { PATH: MAIN_PATH },
  AUTHENTICATION: {
    LOGIN: { PATH: LOGIN_PATH },
    REGISTER: { PATH: REGISTER_PATH },
    INVITED_USER_REGISTER: {
      PATH: INVITED_USER_REGISTER_PATH,
      INVITED_USER_CHANGE_GSM: { PATH: INVITED_USER_CHANGE_GSM_PATH },
      INVITED_USER_VERIFY_GSM: { PATH: INVITED_USER_VERIFY_GSM_PATH },
      INVITED_USER_SUCCESSFUL: { PATH: INVITED_USER_SUCCESSFUL_PATH }
    },
    RESET_PASSWORD: { PATH: RESET_PASSWORD_PATH },
    FORGOT_PASSWORD: { PATH: FORGOT_PASSWORD_PATH },
    RESET_PASSWORD_SAVE: { PATH: RESET_PASSWORD_SAVE_PATH },
    RESET_PASSWORD_SUCCESSFUL: { PATH: RESET_PASSWORD_SUCCESSFUL_PATH },
    GSM_VERIFY: { PATH: GSM_VERIFY_PATH },
    GSM_CHANGE: { PATH: GSM_CHANGE_PATH },
    EMAIL_VERIFY: { PATH: EMAIL_VERIFY_PATH }
  },
  DASHBOARD: {
    SHIPMENT_MANAGEMENT: {
      PATH: SHIPMENT_MANAGEMENT_PATH,
      FTL_ADD: { PATH: FTL_ADD_PATH },
      FTL_PREVIEW: { PATH: FTL_PREVIEW_PATH },
      CONTAINER_ADD: { PATH: CONTAINER_ADD_PATH },
      CONTAINER_PREVIEW: { PATH: CONTAINER_PREVIEW_PATH },
      OTHER_ADD: { PATH: OTHER_ADD_PATH },
      STAGES: {
        SHIPMENT_MANAGEMENT_TRANSPORT_REQUESTS_DETAIL: {
          PATH: SHIPMENT_MANAGEMENT_TRANSPORT_REQUESTS_DETAIL_PATH
        },
        SHIPMENT_MANAGEMENT_TRANSPORTS_ROAD_DETAIL: { PATH: SHIPMENT_MANAGEMENT_TRANSPORTS_ROAD_DETAIL_PATH },
        SHIPMENT_MANAGEMENT_DRIVER_APPROVAL_DETAIL: { PATH: SHIPMENT_MANAGEMENT_DRIVER_APPROVAL_DETAIL_PATH },
        SHIPMENT_MANAGEMENT_DISPATCH_STAGE_DETAIL: { PATH: SHIPMENT_MANAGEMENT_DISPATCH_STAGE_DETAIL_PATH },
        SHIPMENT_MANAGEMENT_DELIVERY_STAGE_DETAIL: { PATH: SHIPMENT_MANAGEMENT_DELIVERY_STAGE_DETAIL_PATH },
        SHIPMENT_MANAGEMENT_DELIVERY_APPROVAL_DETAIL: { PATH: SHIPMENT_MANAGEMENT_DELIVERY_APPROVAL_DETAIL_PATH },
        SHIPMENT_MANAGEMENT_LOADING_STAGE_DETAIL: { PATH: SHIPMENT_MANAGEMENT_LOADING_STAGE_DETAIL_PATH }
      },
      SHIPMENT_COMPLETED: { PATH: SHIPMENT_COMPLETED_PATH },
      SHIPMENT_COMPLETED_DETAIL: { PATH: SHIPMENT_COMPLETED_DETAIL_PATH },
      SHIPMENT_LOAD_PREVIEW: { PATH: SHIPMENT_LOAD_PREVIEW_PATH },
      SHIPMENT_LOAD_ADD: { PATH: SHIPMENT_LOAD_ADD_PATH }
    },
    RAMP_MANAGEMENT: {
      PATH: RAMP_PATH,
      RAMP_ADD: { PATH: RAMP_ADD_PATH },
      RAMP_EDIT: { PATH: RAMP_EDIT_PATH }
    },
    COMPANY_MANAGEMENT: {
      COMPANY_INFORMATION: {
        PATH: COMPANY_INFORMATION_PATH,
        COMPANY_INFORMATION_ADD_INFO: {
          PATH: COMPANY_INFORMATION_ADD_PATH
        },
        COMPANY_INFORMATION_DELETE_ACCOUNT: {
          PATH: COMPANY_INFORMATION_DELETE_ACCOUNT_PATH,
          COMPANY_INFORMATION_DELETE_ACCOUNT_CONFIRM: { PATH: COMPANY_INFORMATION_DELETE_ACCOUNT_CONFIRM_PATH }
        }
      },
      PROJECT: {
        PATH: COMPANY_PROJECT_PATH,
        PROJECT_ADD: { PATH: COMPANY_PROJECT_ADD_PATH },
        PROJECT_EDIT: { PATH: COMPANY_PROJECT_EDIT_PATH },
        PROJECT_ASSIGN_USER: { PATH: COMPANY_PROJECT_ASSIGN_USER_PATH },
        PROJECT_DETAIL: { PATH: COMPANY_PROJECT_DETAIL_PATH }
      },
      SUPPLIER: {
        PATH: COMPANY_SUPPLIER_PATH,
        SUPPLIER_ADD: { PATH: COMPANY_SUPPLIER_ADD_PATH },
        SUPPLIER_EDIT: { PATH: COMPANY_SUPPLIER_EDIT_PATH },
        SUPPLIER_ASSIGN_USER: { PATH: COMPANY_SUPPLIER_ASSIGN_USER_PATH }
      },
      CUSTOMER: {
        PATH: COMPANY_CUSTOMER_PATH,
        CUSTOMER_ADD: { PATH: COMPANY_CUSTOMER_ADD_PATH },
        CUSTOMER_EDIT: { PATH: COMPANY_CUSTOMER_EDIT_PATH },
        CUSTOMER_DETAIL: { PATH: COMPANY_CUSTOMER_DETAIL_PATH }
      },
      ADDRESS: {
        PATH: COMPANY_ADDRESS_PATH,
        ADDRESS_ADD: { PATH: COMPANY_ADDRESS_ADD_PATH },
        ADDRESS_EDIT: { PATH: COMPANY_ADDRESS_EDIT_PATH },
        ADDRESS_DETAIL: { PATH: COMPANY_ADDRESS_DETAIL_PATH }
      }
    },
    USER_MANAGEMENT: {
      PATH: USER_PATH,
      USER_ADD: { PATH: USER_ADD_PATH },
      USER_DETAIL: { PATH: USER_DETAIL_PATH }
    },
    INVENTORY: {
      VEHICLE: {
        PATH: INVENTORY_VEHICLE_PATH,
        VEHICLE_ADD: { PATH: INVENTORY_VEHICLE_ADD_PATH },
        VEHICLE_EDIT: { PATH: INVENTORY_VEHICLE_EDIT_PATH },
        VEHICLE_ASSIGNMENT: { PATH: INVENTORY_VEHICLE_ASSIGNMENT_PATH },
        VEHICLE_DETAIL: { PATH: INVENTORY_VEHICLE_DETAIL_PATH }
      },
      DRIVER: {
        PATH: INVENTORY_DRIVER_PATH,
        DRIVER_ADD: { PATH: INVENTORY_DRIVER_ADD_PATH },
        DRIVER_DETAIL: { PATH: INVENTORY_DRIVER_DETAIL_PATH },
        DRIVER_DETAIL_ASSIGNMENT: { PATH: INVENTORY_DRIVER_DETAIL_ASSIGNMENT_PATH }
      }
    },
    BILL_MANAGEMENT: {
      PATH: BILL_PATH,
      DETAIL: { PATH: BILL_DETAIL_PATH }
    },
    COMPLETED_TRANSPORTATIONS: {
      PATH: COMPLETED_PATH
    }
  },
  SHIPMENT_DETAIL: { PATH: SHIPMENT_DETAIL },
  SHIPMENT_TRACK_LINK: { PATH: SHIPMENT_TRACK_LINK_PATH },
  ERROR: { PATH: ERROR_PATH }
};
