import { createTheme } from '@mui/material/styles';

export const Auththeme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  },

  palette: {
    primary: {
      main: '#002E6D' // Sadece Typography bileşenlerinde kullanılacak renk
    }
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#002E6D', // Seçilmemiş radio button rengi
          '&.Mui-checked': {
            color: '#002E6D' // Seçili radio button rengi
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#002E6D' // Yazı rengini buradan değiştirebilirsiniz
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#002E6D !important'
            },
            '&:hover fieldset': {
              borderColor: '#002E6D !important'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#002E6D !important'
            },
            '& .MuiSvgIcon-root': {
              fill: '#002E6D'
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottomColor: '#002E6D', // Normal durumda alt çizgi rengi
            borderBottomWidth: '2px' // Normal durumda alt çizgi kalınlığı
          },
          '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#002E6D' // Fare üzerine gelindiğinde alt çizgi rengi
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#002E6D' // Tıkladığınızda alt çizgi rengi
          },
          '& .MuiInputBase-input': {
            color: '#002E6D' // Metin rengi
          }
        }
      }
    }
  }
});
