import React, { useState } from 'react';
// MaterialUI
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  Select
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
//import ViewUserInformation from './UserInformation/ViewUserInformation'
//import Settings from './UserInformation/Settings'

// firebase-messaging-sw.js dosyası oluşturun
import { initializeApp } from 'firebase/app';
import { getMessaging, deleteToken, getToken } from 'firebase/messaging';

import { default as SmallProfileIcon } from '../../assets/dashboard/main/small_profile_icon.svg';
import { default as SmallSettingsIcon } from '../../assets/dashboard/main/small_settings_icon.svg';
import { default as LeftArrowIcon } from '../../assets/dashboard/main/left_arrow_icon.svg';
import { default as SmallThemeIcon } from '../../assets/dashboard/main/small_theme_icon.svg';
import { default as LogoutIcon } from '../../assets/dashboard/main/logout_icon.svg';
import { default as NavbarIcon } from '../../assets/dashboard/main/navbar_down_icon.svg';

import NotificationMenu from './notification';
import Settings from './settings';
import { useDispatch } from 'react-redux';
import { LOGGED_OUT } from '../../page/Login/reducer/auth-slice';

import Profile from './profile/index';
import { useGetUserProfileQuery } from '../../store/api/auth/api';
import LangSelector from '@/components/LangSelector.tsx';
import { useTranslation } from 'react-i18next';

// Firebase projesinden aldığınız yapılandırma bilgilerini buraya ekleyin
const firebaseConfig = {
  apiKey: 'AIzaSyBmUHwuHwD-hqWHD94OCovY27ZrGK5TQVw',
  authDomain: 'yukcepte-new-version.firebaseapp.com',
  databaseURL: 'https://yukcepte-new-version-default-rtdb.firebaseio.com',
  projectId: 'yukcepte-new-version',
  storageBucket: 'yukcepte-new-version.appspot.com',
  messagingSenderId: '583371430275',
  appId: '1:583371430275:web:d36d93dad1ce6bc2d2feb6',
  measurementId: 'G-1KTT6GGDK3'
};

// Firebase uygulamasını başlatın
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const Navbar = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(document.fullscreen);
  const [showProfile, setShowProfile] = useState(false);
  const [settings, setSettings] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userProfile } = useGetUserProfileQuery();
  const { t } = useTranslation();

  //Navbar functions-------
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleSetIsFullScreen = (): void => {
    setIsFullscreen(document.fullscreen);
  };
  const changeShowProfileStatus = (newStatus: boolean): void => {
    setShowProfile(newStatus);
  };
  const changeShowSettings = (newStatus: boolean): void => {
    setSettings(newStatus);
  };

  const handleLogOut = () => {
    dispatch(LOGGED_OUT());
    // getToken(messaging, {
    //   vapidKey: 'BCgrhz36om6KDi6IGA9UgqGzEtNX0l5Kc5V4rbuDevhSS3_dc2QZLu_la1zakXzMf7f44dA-4Ii5lNDNaKrmN1g'
    // })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       // Token'ı sil
    //       deleteToken(messaging)
    //         .then(() => {
    //           console.log('FCM token silindi.');
    //         })
    //         .catch((err) => {
    //           console.error('Token silinirken hata:', err);
    //         });
    //       localStorage.removeItem('isTokenSent');
    //     } else {
    //       console.log('Token mevcut değil.');
    //     }
    //   })
    //   .catch((err) => {
    //     console.error('Token alınırken hata:', err);
    //   });
  };

  return (
    <Grid xs={12} item container justifyContent="end" paddingRight="33px">
      <Stack direction="row" alignItems="center">
        <Stack marginRight="40px">
          <NotificationMenu />
        </Stack>

        <Divider
          sx={{
            marginTop: '3px',
            height: '2.6rem',
            borderRightWidth: 2,
            borderColor: '#002E6D',
            marginRight: '30px'
          }}
          orientation="vertical"
          flexItem
        />
        <Stack direction="column" alignItems="flex-start" marginRight="19px" spacing={0}>
          <Typography color="#002E6D" variant="body2">
            {userProfile?.name}
          </Typography>
          <Typography color="#002E6D" variant="caption">
            {userProfile?.email}
          </Typography>
        </Stack>

        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginLeft: '20px' }}>
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                backgroundColor: '#002E6D',
                borderRadius: '4px' // Kare şekil için
              }}
            >
              {userProfile?.name.charAt(0).toUpperCase()}
            </Avatar>
            <Tooltip title={t('accountSettings')}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <img style={{ width: '10px', marginLeft: '2px' }} src={NavbarIcon} alt="Small Profile Icon" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                paddingTop: '15px',
                paddingBottom: '15px',
                width: '280px',
                boxShadow: '0px 0px 6px #00000029',
                overflow: 'visible',
                mt: 3.5,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 25,
                  height: 25,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => setShowProfile(true)}>
              <Stack direction="row" spacing="13px">
                <img src={SmallProfileIcon} alt="Small Profile Icon" />
                <Typography color={'#002E6D'} variant="body2">
                  {t('profile')}
                </Typography>
              </Stack>
            </MenuItem>
            {/* <MenuItem onClick={() => setSettings(true)}>
              <Stack direction="row" spacing="13px">
                <img src={SmallSettingsIcon} alt="Small Settings Icon" />
                <Typography color={'#002E6D'} variant="body2">
                  {t('settings')}
                </Typography>
              </Stack>
            </MenuItem> */}
            <LangSelector />
            {/* <MenuItem>
              <Stack justifyContent="space-between" direction="row" width="100%">
                <Stack direction="row" spacing="13px">
                  <img src={SmallThemeIcon} alt="Small Theme Icon" />
                  <Typography color={'#002E6D'} variant="body2">
                    {t('theme')}
                  </Typography>
                </Stack>
                <img src={LeftArrowIcon} alt="Left Arrow Icon" />
              </Stack>
            </MenuItem> */}
            <Divider
              style={{
                border: '1px solid #002E6D',
                opacity: '0.2'
              }}
            />
            <MenuItem onClick={handleLogOut}>
              <Stack direction="row" spacing="13px" alignItems="center">
                <img src={LogoutIcon} alt="Logout Icon" />
                <Typography color={'#002E6D'} variant="body2">
                  {t('logout')}
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </React.Fragment>
      </Stack>
      {/* {showProfile && <ViewUserInformation
      changeDrawerStatus={changeShowProfileStatus}
      drawerStatus={showProfile}
    />} */}
      {/* Navbar companent is here!! */}
      <Settings changeDrawerStatus={changeShowSettings} drawerStatus={settings} />
      <Profile changeDrawerStatus={changeShowProfileStatus} drawerStatus={showProfile} />
    </Grid>
  );
};

export default Navbar;
