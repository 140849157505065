import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './config';

const URL = import.meta.env.VITE_SHIPMENT_API_URL;

export const shipmentApiService = createApi({
  reducerPath: 'shipmentApi',
  baseQuery: baseQuery(URL),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: () => ({}),
  tagTypes: ['Shipment']
});
