import { useTranslation } from 'react-i18next';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { default as SmallCountryIcon } from '@/assets/dashboard/main/small_country_icon.svg';
import { default as EnCountryIcon } from '@/assets/dashboard/main/en_icon.svg';
import React from 'react';

export default function LangSelector() {
  const { i18n, t } = useTranslation();

  const langs = [
    {
      value: 'en',
      img: EnCountryIcon
    },
    {
      value: 'tr',
      img: SmallCountryIcon
    }
  ];

  return (
    <Select
      style={{ border: 'none !important' }}
      label={t('lang')}
      sx={{
        '& fieldset': {
          border: 'none'
        },
        width: '100%'
      }}
      variant="outlined"
      value={i18n.language.split('-')[0]}
      onChange={async (e) => {
        await i18n.changeLanguage(e.target.value);
      }}
    >
      {langs.map((lang, index) => {
        return (
          <MenuItem key={index} value={lang.value}>
            <Stack justifyContent="space-between" direction="row" width="100%">
              <Stack direction="row" spacing="13px">
                <img src={lang.img} alt="Small Country Icon" />
                <Typography color={'#002E6D'} variant="body2">
                  {t('lang')} : {t(lang.value as 'en' & 'tr')}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        );
      })}
    </Select>
  );
}
