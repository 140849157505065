import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router.tsx';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { NotifyContainer } from './components/notification/notify.tsx';
import { Provider } from 'react-redux';
import { store } from './store/store.ts';
import AuthProvider from './providers/AuthProvider.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';
import { APIProvider } from '@vis.gl/react-google-maps';
import '@/providers/i18n.ts';
import * as Sentry from '@sentry/react';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  // İzleme oranı. Tüm işlemleri izlemek istiyorsanız 1.0 yapabilirsiniz, ancak bu üretimde performansı etkileyebilir.
  tracesSampleRate: 0.1, // %10 oranında örnekleme ile tüm projeyi izlemek için
  tracePropagationTargets: ['*'], // Tüm URL'ler için izlemeyi etkinleştirir

  // Oturum Yeniden Oynatma Ayarları
  replaysSessionSampleRate: 0.05, // Oturumların %5'ini yeniden oynatma için kaydeder.
  replaysOnErrorSampleRate: 1.0 // Hata oluştuğunda %100 oturum kaydı alır.
});

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <APIProvider apiKey={API_KEY}>
      <AuthProvider>
        <NotifyContainer autoClose={1500} bodyClassName="Notify__toast-body" position="top-center" />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
          <Router />
        </LocalizationProvider>
      </AuthProvider>
    </APIProvider>
  </Provider>
);
