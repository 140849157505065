import LinkBehavior from './linkBehavior';
import { Components, LinkProps } from '@mui/material';

export const MuiLink: Components = {
  MuiLink: {
    defaultProps: {
      component: LinkBehavior
    } as LinkProps
  }
};
