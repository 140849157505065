// for Redirect
import { MuiMenu } from './menu';
import { MuiLink } from './link';
import { MuiButton } from './button';
import { MuiMenuItem } from './menuItem';
import { trTR } from '@mui/material/locale';
import { MuiButtonBase } from './buttonBase';
import { colorPalette } from './colorPalette';
import { typographyOptions } from './typography';
import { ThemeOptions, createTheme } from '@mui/material/styles';

export const dashboardTheme = createTheme(
  {
    spacing: 5,
    palette: { ...colorPalette },
    typography: { ...typographyOptions },
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#002E6D', // Seçilmemiş radio button rengi
            '&.Mui-checked': {
              color: '#002E6D' // Seçili radio button rengi
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: '#002E6D' // Yazı rengini buradan değiştirebilirsiniz
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: '#002E6D'
            },
            '& .MuiInputBase-input': {
              color: '#002E6D',
              padding: '8px 30px 8px 17px',
              borderRadius: '5px',
              lineHeight: '1.9'
            },
            '& .MuiSelect-select': {
              '&:focus': {
                backgroundColor: '#ffffff',
                borderRadius: '5px'
              }
            },
            '& .MuiSelect-select-MuiInputBase-input': {
              paddingRight: '50px'
            },
            '& .MuiList-root': {
              borderRadius: '5px'
            },
            '& .DashboardSelectIconStyle': {
              position: 'absolute',
              right: '14.5px',
              top: 'calc(50% - 0.30em)',
              userSelect: 'none',
              height: '12px',
              paddingLeft: '30px',
              width: '20px',
              display: 'inline-block',
              flexShrink: 0,
              pointerEvents: 'none'
            },
            '&.Mui-disabled': {
              opacity: 0.4
            },
            '& .MuiSvgIcon-root': {
              fontSize: '40px',
              color: '#002E6D'
            }
          }
        }
      },
      MuiButton: {
        // Buton özelliğini ekleyin
        styleOverrides: {
          root: {
            width: '100%', // Genişlik 100%
            textTransform: 'none', // Büyük/küçük harf dönüşümü yok
            border: '1px solid #002E6D', // 1 piksel kalınlığında, #002E6D renkli kenarlık
            color: '#ffffff', // Beyaz metin rengi
            background: '#002E6D', // #002E6D arka plan rengi
            borderRadius: '9px', // 9 piksel yuvarlatılmış kenarlar
            opacity: '1',
            '&:hover': {
              backgroundColor: '#002E6D'
            },
            fontSize: '14px',
            py: '10px !important'
          },
          white: {
            color: '#002E6D',
            backgroundColor: '#ffffff',
            border: 0,
            borderRadius: '5px !important',
            padding: '6px 20px !important',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            '&:hover': {
              backgroundColor: '#ffffff'
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#002E6D',
              borderWidth: '2px'
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#002E6D'
            },
            '& .MuiOutlinedInput-input': {
              padding: '12px 14px',
              height: '1.2rem'
            }
          }
        }
      },
      ...MuiLink,
      ...MuiButtonBase,
      ...MuiMenu,
      ...MuiMenuItem
    }
  },
  trTR as ThemeOptions
);
