import { createSlice } from '@reduxjs/toolkit';

export interface ProjectSelectedState {
  project: string | null;
}

const initialState: ProjectSelectedState = {
  project: localStorage.getItem('selectedProject') || null
};

export const projcetSelectedSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    SET_PROJECT: (state, action) => {
      state.project = action.payload;
      localStorage.setItem('selectedProject', action.payload);
    }
  }
});

export const { SET_PROJECT } = projcetSelectedSlice.actions;
export default projcetSelectedSlice.reducer;
