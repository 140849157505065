import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loader() {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(5px)',
        zIndex: 999
      }}
    >
      <CircularProgress />
    </div>
  );
}
