import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleInventoryState } from './types';

const initialState: VehicleInventoryState = {
  truckTypeIds: {},
  ownershipTypes: {
    OWNER: true,
    RENTAL: true
  }
};

export const vehicleInventorySlice = createSlice({
  name: 'vehicleInventory',
  initialState,
  reducers: {
    toggleTruckTypeId: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.truckTypeIds[id] = !state.truckTypeIds[id];
    },
    toggleOwnershipType: (state, action: PayloadAction<string>) => {
      const type = action.payload;
      state.ownershipTypes[type] = !state.ownershipTypes[type];
    },
    setTruckTypeIds: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.truckTypeIds = action.payload;
    }
  }
});

export const { toggleTruckTypeId, toggleOwnershipType, setTruckTypeIds } = vehicleInventorySlice.actions;

export default vehicleInventorySlice.reducer;
