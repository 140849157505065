import React from 'react';
import { toast, ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/scss/app/components/common/notify.css';

import { default as NotifySuccessIcon } from '../../assets/notification/icons/successNotificationIcon.svg';
import { default as NotifyErrorIcon } from '../../assets/notification/icons/errorNotificationIcon.svg';
import { default as NotifyWarningIcon } from '../../assets/notification/icons/warningNotificationIcon.svg';

export enum NotifyType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

const NotifyIconUrls: Record<NotifyType, string> = {
  [NotifyType.Info]: NotifySuccessIcon,
  [NotifyType.Success]: NotifySuccessIcon,
  [NotifyType.Error]: NotifyErrorIcon,
  [NotifyType.Warning]: NotifyWarningIcon
};

interface INotify {
  containerId?: string;
  title?: React.ReactNode | string;
  message: React.ReactNode | string;
}

interface INotifyWithType extends INotify {
  notifyType: NotifyType;
}

const DefaultContainerId: string = '__container1';

interface NotifyContainerProps extends ToastContainerProps {}

export const NotifyContainer: React.FC<NotifyContainerProps> = ({ containerId, ...props }) => {
  const actualContainerId = containerId ?? DefaultContainerId;

  return <ToastContainer containerId={actualContainerId} closeOnClick {...props} />;
};

const NotifyContent: React.FC<{
  title?: React.ReactNode | string;
  message: React.ReactNode | string;
  iconUrl: string;
}> = ({ title, message, iconUrl }) => {
  return (
    <div className="Notify__notify-body">
      <img className="Notify__notify-icon" src={iconUrl} alt={'notify'} />
      <div className="Notify__notify-text-container" style={{ paddingRight: '1.5rem' }}>
        <div style={{ fontWeight: 'bold', paddingBottom: '5px' }} className="Notify__notify-title">
          {title}
        </div>
        <div
          style={{
            fontWeight: 'bold',
            opacity: '0.5',
            fontFamily: 'sans-serif'
          }}
          className="Notify__notify-message"
        >
          {message}
        </div>
      </div>
    </div>
  );
};

export const notify = ({
  containerId = DefaultContainerId,
  title,
  message,
  notifyType
}: INotifyWithType): number | string => {
  return toast(<NotifyContent title={title} message={message} iconUrl={NotifyIconUrls[notifyType]} />, {
    containerId,
    type: notifyType
  });
};

export const notifySuccess = ({ containerId = DefaultContainerId, title, message }: INotify): number | string => {
  return notify({
    containerId,
    title,
    message,
    notifyType: NotifyType.Success
  });
};

export const notifyError = ({ containerId = DefaultContainerId, title, message }: INotify): number | string => {
  return notify({ containerId, title, message, notifyType: NotifyType.Error });
};

export const notifyWarning = ({ containerId = DefaultContainerId, title, message }: INotify): number | string => {
  return notify({
    containerId,
    title,
    message,
    notifyType: NotifyType.Warning
  });
};
