import { authApiService } from '../authApiService';
import * as dto from './dto';

const authApi = authApiService.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<dto.LoginResponseDto, dto.LoginRequestDto>({
      query: (body) => ({
        url: `/user/login`,
        method: 'POST',
        body
      })
    }),
    register: build.mutation<void, dto.RegisterRequestDto>({
      query: (body) => ({
        url: `/user/register`,
        method: 'POST',
        body
      })
    }),
    phoneVerification: build.mutation<void, dto.PhoneVerificationRequestDto>({
      query: (body) => ({
        url: `/user/verify-phone`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    changePhoneNumber: build.mutation<void, dto.ChangePhoneNumberRequestDto>({
      query: (body) => ({
        url: `/user/change-phone-number`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    resetPhoneOtp: build.mutation<void, dto.ResetPhoneOtpRequestDto>({
      query: (body) => ({
        url: `/user/reset-phone-otp`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),

    forgotPassword: build.mutation<void, dto.ResetPhoneOtpRequestDto>({
      query: (body) => ({
        url: `/user/forgot-password`,
        method: 'PUT',
        body
      })
    }),

    resetPassword: build.mutation<void, dto.ResetPasswordRequestDto>({
      query: (body) => ({
        url: `/user/reset-password`,
        method: 'PUT',
        body
      })
    }),
    emailVerify: build.mutation<void, dto.EmailVerifyRequestDto>({
      query: (body) => ({
        url: `/user/verify-email`,
        method: 'PUT',
        body
      })
    }),
    me: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/user/me`,
        method: 'GET'
      })
    }),
    getUserProfile: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/user/profile`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    updateUserProfile: build.mutation<void, dto.UpdateUserProfileRequestDto>({
      query: (body) => ({
        url: `/user/profile`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    userChangePassword: build.mutation<void, dto.ChangePasswordRequestDto>({
      query: (body) => ({
        url: `/user/change-password`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    verifyPassword: build.mutation<void, dto.VerifyPasswordRequestDto>({
      query: ({ password }) => ({
        url: `/user/verify-password`,
        method: 'POST',
        body: { password },
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
});

export const {
  useLoginMutation,
  useRegisterMutation,
  usePhoneVerificationMutation,
  useChangePhoneNumberMutation,
  useResetPhoneOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useEmailVerifyMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUserChangePasswordMutation,
  useLazyMeQuery,
  useLazyGetUserProfileQuery,
  useVerifyPasswordMutation
} = authApi;
