import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import tr from '../../public/locales/tr/translation.json';
import en from '../../public/locales/en/translation.json';

export type TranslationKeys = keyof typeof tr & keyof typeof en;

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation';
    resources: {
      translation: typeof tr & typeof en;
    };
  }
}

i18n.use(LanguageDetector).use(initReactI18next).use(Backend).init({
  fallbackLng: 'tr'
});
