import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './config';
const URL = import.meta.env.VITE_COMPANY_API_URL;

export const corporateApiService = createApi({
  reducerPath: 'corporateApi',
  baseQuery: baseQuery(URL),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: () => ({}),
  tagTypes: ['CompanyVehicleList']
});
