import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Index } from './types';

const initialState = {
  pageMode: 'profileView'
} as Index;

export const dashboardProfileSlice = createSlice({
  name: 'dashboardProfileSlice',
  initialState,
  reducers: {
    reset: () => initialState,
    setPageMode: (state, action: PayloadAction<string>) => {
      state.pageMode = action.payload;
    }
  }
});

export const { reset, setPageMode } = dashboardProfileSlice.actions;

export default dashboardProfileSlice.reducer;
