import {
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText
} from '@mui/material';
import { default as PasswordIcon } from '../../../assets/dashboard/main/password_yellow_icon.svg';
import { default as PasswordBlueIcon } from '../../../assets/dashboard/main/password_blue_icon.svg';
import { default as VisibilityOnIcon } from '../../../assets/dashboard/main/visibilityOnYellowIcon.svg';
import { default as VisibilityOffIcon } from '../../../assets/dashboard/main/visibility_off_yellow_icon.svg';
import { default as ErrorIcon } from '../../../assets/dashboard/main/validation_error_icon.svg';
//import Button from "@mui/material/Button";
import { useUserChangePasswordMutation } from '../../../store/api/auth/api';
import { default as LeftArrowIcon } from '../../../assets/dashboard/main/left-arrow-icon-v2.svg';
import { useFormik } from 'formik';
import { notifyError, notifySuccess } from '../../../components/notification/notify';
import { ChangePasswordRequestDto } from '../../../store/api/auth/dto';
import { useDispatch } from 'react-redux';
import { reset } from './reducer/slice';
import { useTranslation } from 'react-i18next';

interface IPasswordChange {
  oldPassword: string;
  newPassword: string;
  newPasswordVerify: string;
  isNewPasswordVisible: boolean;
  isNewPasswordVerifyVisible: boolean;
  isOldPasswordVisible: boolean;
  errors: {
    isOldPassword: boolean;
    isNewPassword: boolean;
    isNewPasswordVerify: boolean;
  };
}

const PasswordChange = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [changePassword] = useUserChangePasswordMutation();

  const formData = useFormik<IPasswordChange>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordVerify: '',
      isNewPasswordVisible: false,
      isNewPasswordVerifyVisible: false,
      isOldPasswordVisible: false,
      errors: {
        isOldPassword: false,
        isNewPassword: false,
        isNewPasswordVerify: false
      }
    },
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const {
    oldPassword,
    newPassword,
    newPasswordVerify,
    isNewPasswordVerifyVisible,
    isNewPasswordVisible,
    isOldPasswordVisible,
    errors
  } = formData.values;

  console.log(
    'formData',
    oldPassword,
    newPassword,
    newPasswordVerify,
    isNewPasswordVerifyVisible,
    isNewPasswordVisible,
    isOldPasswordVisible,
    errors
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formData.setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      errors: {
        ...prevValues.errors,
        [`is${name.charAt(0).toUpperCase() + name.slice(1)}`]: !value.trim()
      }
    }));
  };

  const handleSubmit = async () => {
    const requestData: ChangePasswordRequestDto = {
      currentPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: newPasswordVerify
    };

    try {
      await changePassword(requestData).unwrap();

      notifySuccess({ message: t('passwordChanged'), title: t('success') });
      dispatch(reset());
    } catch (error) {
      // Hata durumunda yapılacaklar
      notifyError({
        message: t('passwordChangeError'),
        title: t('oneError')
      });
    }
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="4px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <img src={PasswordBlueIcon} alt="PersonIcon" />
            <Typography color={'#002E6D'} variant="h6">
              {t('editProfile')}
            </Typography>{' '}
          </Stack>
          <IconButton
            disableRipple
            onClick={() => {
              dispatch(reset());
            }}
          >
            <img src={LeftArrowIcon} alt="LeftArrowIcon" />
            <Typography sx={{ opacity: '60%' }} variant="h6">
              {t('goBack')}
            </Typography>{' '}
          </IconButton>
        </Stack>

        <Stack direction="column" justifyContent="center" pt="52px">
          <Grid xs={12} pb="60px">
            <FormControl fullWidth error={errors.isOldPassword}>
              <TextField
                id="outlined-basic"
                name="oldPassword"
                label={t('oldPassword')}
                variant="standard"
                size="medium"
                placeholder={t('enterCurrentPassword')}
                fullWidth
                type={isOldPasswordVisible ? 'text' : 'password'}
                value={oldPassword}
                onChange={handleChange}
                sx={{ label: { opacity: '60%' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pb: 1 }}>
                      <img src={PasswordIcon} alt="PasswordIcon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pb: 1 }}>
                      <IconButton
                        onClick={() =>
                          formData.setValues({
                            ...formData.values,
                            isOldPasswordVisible: !isOldPasswordVisible
                          })
                        }
                      >
                        {isOldPasswordVisible === true ? (
                          <img src={VisibilityOffIcon} alt="VisibilityOffIcon" />
                        ) : (
                          <img src={VisibilityOnIcon} alt="VisibilityOnIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.isOldPassword && (
                <FormHelperText>
                  <IconButton size="small" aria-label="error">
                    <img src={ErrorIcon} alt="MailIcon" />
                  </IconButton>
                  {t('notNull')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} pb="60px">
            <FormControl fullWidth error={errors.isNewPassword}>
              <TextField
                id="outlined-basic"
                name="newPassword"
                label={t('newPassword')}
                variant="standard"
                size="medium"
                fullWidth
                placeholder={t('enterNewPassword')}
                type={isNewPasswordVisible ? 'text' : 'password'}
                value={newPassword}
                onChange={handleChange}
                sx={{ label: { opacity: '60%' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pb: 1 }}>
                      <img src={PasswordIcon} alt="PersonIcon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pb: 1 }}>
                      <IconButton
                        onClick={() =>
                          formData.setValues({
                            ...formData.values,
                            isNewPasswordVisible: !isNewPasswordVisible
                          })
                        }
                      >
                        {isNewPasswordVisible === true ? (
                          <img src={VisibilityOffIcon} alt="VisibilityOffIcon" />
                        ) : (
                          <img src={VisibilityOnIcon} alt="VisibilityOnIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.isNewPassword && (
                <FormHelperText>
                  <IconButton size="small" aria-label="error">
                    <img src={ErrorIcon} alt="MailIcon" />
                  </IconButton>
                  {t('notNull')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} pb="60px">
            <FormControl fullWidth error={errors.isNewPasswordVerify}>
              <TextField
                id="outlined-basic"
                name="newPasswordVerify"
                label={t('confirmNewPassword')}
                variant="standard"
                size="medium"
                fullWidth
                placeholder={t('confirmNewPasswordPrompt')}
                type={isNewPasswordVerifyVisible ? 'text' : 'password'}
                value={newPasswordVerify}
                onChange={handleChange}
                sx={{ label: { opacity: '60%' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ pb: 1 }}>
                      <img src={PasswordIcon} alt="PersonIcon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ pb: 1 }}>
                      <IconButton
                        onClick={() =>
                          formData.setValues({
                            ...formData.values,
                            isNewPasswordVerifyVisible: !isNewPasswordVerifyVisible
                          })
                        }
                      >
                        {isNewPasswordVerifyVisible === true ? (
                          <img src={VisibilityOffIcon} alt="VisibilityOffIcon" />
                        ) : (
                          <img src={VisibilityOnIcon} alt="VisibilityOnIcon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {errors.isNewPasswordVerify && (
                <FormHelperText>
                  <IconButton size="small" aria-label="error">
                    <img src={ErrorIcon} alt="asdIcon" />
                  </IconButton>
                  {t('notNull')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Grid xs={4}>
            <Button onClick={handleSubmit}>{t('save')}</Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PasswordChange;
