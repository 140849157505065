import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Index } from './types';

const initialState = {
  vehcileAssigmentDialogIsOpen: false,
  assignmentsDialogIsOpen: false,
  driverId: ''
} as Index;

export const driverInventorySlice = createSlice({
  name: 'driverInventorySlice',
  initialState,
  reducers: {
    reset: () => initialState,
    setVehicleAssigmentDialogIsOpen: (state, action: PayloadAction<boolean>) => {
      state.vehcileAssigmentDialogIsOpen = action.payload;
    },
    setDriverId: (state, action: PayloadAction<string>) => {
      state.driverId = action.payload;
    },
    setAssignmentsDialogIsOpen: (state, action: PayloadAction<boolean>) => {
      state.assignmentsDialogIsOpen = action.payload;
    }
  }
});

export const { reset, setVehicleAssigmentDialogIsOpen, setDriverId, setAssignmentsDialogIsOpen } =
  driverInventorySlice.actions;

export default driverInventorySlice.reducer;
