import { Box, Container, CssBaseline, Typography } from '@mui/material';
//import loginLeftIntroImg from '../images/login-left-intro.png';
import LogoImg from '../../assets/login/logo.png';
import { useTranslation } from 'react-i18next';
//import BgImageImg from '../../assets/login/logo.png'

export const LoginLeftIntro = () => {
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      <CssBaseline />
      <Box
        component="img"
        src="https://cdn.zeplin.io/62c01ce5d3cea618dbdcb620/assets/02669bfb-3379-4658-9298-85fa5686a85d-optimized.png"
        sx={{
          width: '100%',
          height: '100vh',
          objectFit: 'cover',
          position: 'absolute',
          zIndex: -1
        }}
      />
      <Container maxWidth="sm" sx={{ position: 'relative', zIndex: 1 }}>
        <Box textAlign="left" pt={10} pb={5}>
          <Box component="img" src={LogoImg} alt="Logo" sx={{ width: '100%', maxWidth: 255 }} />
        </Box>
        <Box textAlign="left" py={2}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ bgcolor: '#002E6D', color: 'white', display: 'inline-block', px: 1 }}
          >
            {t('yourCargoEndToEnd')}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ bgcolor: '#002E6D', color: 'white', fontWeight: 'bold', display: 'inline-block', px: 1 }}
          >
            {t('underTIRPORTGuarantee')}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};
