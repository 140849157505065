//import './App.css'
import { Box, Container, ThemeProvider, Stack, styled } from '@mui/material';
import { dashboardTheme } from '../../theme/dashboard/index';
import Sidebar from './sidebar';
import { Outlet, useNavigation } from 'react-router-dom';
import ProgressBar from 'react-topbar-progress-indicator';

// firebase-messaging-sw.js dosyası oluşturun
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { usePostDeviceTokenMutation } from '../../store/api/general/api';
import React from 'react';

// Firebase projesinden aldığınız yapılandırma bilgilerini buraya ekleyin
const firebaseConfig = {
  apiKey: 'AIzaSyBmUHwuHwD-hqWHD94OCovY27ZrGK5TQVw',
  authDomain: 'yukcepte-new-version.firebaseapp.com',
  databaseURL: 'https://yukcepte-new-version-default-rtdb.firebaseio.com',
  projectId: 'yukcepte-new-version',
  storageBucket: 'yukcepte-new-version.appspot.com',
  messagingSenderId: '583371430275',
  appId: '1:583371430275:web:d36d93dad1ce6bc2d2feb6',
  measurementId: 'G-1KTT6GGDK3'
};

// Firebase uygulamasını başlatın
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

ProgressBar.config({
  barColors: {
    '0': '#F4BC2F',
    '1.0': '#F4BC2F'
  },
  shadowBlur: 5,
  barThickness: 4
});

function DashbordLayout() {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar
  }));

  const navigation = useNavigation();
  const [permission, setPermission] = React.useState<NotificationPermission>(Notification.permission);
  const [added, setAdded] = React.useState<boolean>(false);

  const [postDeviceToken] = usePostDeviceTokenMutation();

  async function requestPermission() {
    if (permission === 'default' || permission === 'denied') {
      const responsePermission = await Notification.requestPermission();
      if (responsePermission === 'granted') {
        setPermission('granted');
      }
    }
  }

  React.useEffect(() => {
    if (permission != 'granted') requestPermission();
  }, []);

  React.useEffect(() => {
    async function addSW() {
      await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      setAdded(true);
    }

    if (permission === 'granted') {
      addSW();
    }
  }, [permission]);

  React.useEffect(() => {
    const messaging = getMessaging(app);

    // Ön planda gelen mesajları ele al
    const handleMessage = (payload) => {
      // Bildirim detaylarını ayarlayın
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon
        // Diğer seçenekler
      };

      // Bildirimi gösterin
      if (Notification.permission === 'granted') {
        new Notification(notificationTitle, notificationOptions);
      }
    };

    // onMessage olayını dinle
    const unsubscribe = onMessage(messaging, handleMessage);

    // Temizleme işlemi
    return () => {
      unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    async function checkPermission() {
      console.log({
        permission,
        added,
        isTokenSent: localStorage.getItem('isTokenSent')
      });
      if (permission === 'granted' && added && localStorage.getItem('isTokenSent') !== 'true') {
        try {
          const currentToken = await getToken(messaging, {
            vapidKey: 'BCgrhz36om6KDi6IGA9UgqGzEtNX0l5Kc5V4rbuDevhSS3_dc2QZLu_la1zakXzMf7f44dA-4Ii5lNDNaKrmN1g'
          });
          if (currentToken && !localStorage.getItem('isTokenSent')) {
            await postDeviceToken(currentToken).unwrap();
            localStorage.setItem('isTokenSent', 'true');
          } else {
            console.log("Cihaz token'ı alınamadı. İzin verilmemiş olabilir.");
          }
        } catch (err) {
          console.error('Token alınırken bir hata oluştu:', err);
        }
      }
    }

    checkPermission();
  }, [added, permission, postDeviceToken]);

  return (
    <>
      {navigation.state !== 'idle' && <ProgressBar />}
      <ThemeProvider theme={dashboardTheme}>
        <Container
          disableGutters
          maxWidth={false}
          style={{
            marginLeft: -30
          }}
        >
          <Stack direction={'row'}>
            <Sidebar></Sidebar>
            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
              <DrawerHeader />
              <Outlet />
            </Box>
          </Stack>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default DashbordLayout;
