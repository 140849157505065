import React from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Switch,
  Typography,
  useTheme
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { default as SettingsIcon } from '../../assets/dashboard/main/settings_icon.svg';
import { default as CancelIcon } from '../../assets/dashboard/main/cancel_icon.svg';
import { useTranslation } from 'react-i18next';
//import { useTranslation } from 'react-i18next';

interface ISettings {
  changeDrawerStatus: (newDrawerStatus: boolean) => void;
  drawerStatus: boolean;
}

const Settings = (props: ISettings): JSX.Element => {
  const { changeDrawerStatus, drawerStatus } = props;
  const theme = useTheme();
  const toggleDrawer = () => {
    changeDrawerStatus(!drawerStatus);
  };

  const formik = useFormik({
    initialValues: {
      switch1: false,
      switch2: false,
      switch3: false,
      switch4: false,
      switch5: false,
      switch6: false,
      switch7: false
    },
    validationSchema: Yup.object({
      // Buraya doğrulama şemasını ekleyebilirsiniz
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const Switcher = {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#FFC400',
      '&:hover': {
        backgroundColor: '#FFC400'
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#FFC400',
      opacity: 1
    },
    '& .MuiSwitch-switchBase.Mui-checked:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#00245B'
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#D9D9D980',
      opacity: 1
    }
  };

  const buttonStyle = {
    height: '47px',
    border: '1px solid #002E6D',
    color: 'white',
    background: '#002E6D',
    borderRadius: '9px',
    opacity: 1, // Opaklık ayarı
    '&:hover': {
      background: '#002E6D',
      color: 'white',
      '& .MuiTypography-root': {
        color: 'white'
      }
    },
    '& .MuiTypography-root': {
      color: 'white'
    }
  };
  const { t } = useTranslation();

  return (
    <SwipeableDrawer anchor={'right'} open={drawerStatus} onClose={toggleDrawer} onOpen={toggleDrawer}>
      <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
        <Grid xs={12} item height="100%">
          <Stack direction="row" justifyContent="space-between" marginBottom="4px" alignItems="center">
            <Stack direction="row" spacing="13px" alignItems="center">
              <img src={SettingsIcon} alt="Logout Icon" />
              <Typography color={'#002E6D'} variant="h6">
                {t('settings')}
              </Typography>{' '}
              {/* Default title */}
            </Stack>
            <IconButton disableRipple onClick={toggleDrawer}>
              <img src={CancelIcon} alt="Logout Icon" />
            </IconButton>
          </Stack>
          {/* buraya turnback gelebilir */}
          <>
            <Divider sx={{ marginTop: '32px', marginBottom: '40px' }} />
            <Stack spacing="33px">
              <Stack spacing="4px">
                <Typography variant="h6">{t('emailNotifications')}</Typography>
                <Typography variant="caption">{t('notificationPreferences')}</Typography>
              </Stack>
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch1}
                    onChange={formik.handleChange}
                    name="switch1"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">{t('driverInventory')}</Typography>
                    <Typography variant="caption">{t('vehicleContractEndDateEmailNotification')}</Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch2}
                    onChange={formik.handleChange}
                    name="switch2"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">{t('vehicleInventory')}</Typography>
                    <Typography variant="caption">{t('vehicleContractEndDateEmailNotification')}</Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch3}
                    onChange={formik.handleChange}
                    name="switch3"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">{t('location')}</Typography>
                    <Typography variant="caption" sx={{ color: '#002E6D' }} color="text.secondary">
                      {t('location30MinutesNotAvailableEmailNotification')}
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch4}
                    onChange={formik.handleChange}
                    name="switch4"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">POD Proof of Delivery</Typography>
                    <Typography variant="caption" sx={{ color: '#002E6D' }} color="text.secondary">
                      {t('location30MinutesNotAvailableEmailNotification')}
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch5}
                    onChange={formik.handleChange}
                    name="switch5"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">
                      {t('location30MinutesNotAvailableEmailNotification')}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#002E6D' }} color="text.secondary"></Typography>
                  </Stack>
                }
              />
            </Stack>
            <Divider sx={{ marginTop: '32px', marginBottom: '40px' }} />
            <Stack spacing="33px">
              <Stack spacing="4px">
                <Typography variant="h6">{t('location30MinutesNotAvailableEmailNotification')}</Typography>
                <Typography variant="caption">{t('location30MinutesNotAvailableEmailNotification')}</Typography>
              </Stack>
              <FormControlLabel
                control={
                  <Switch
                    sx={Switcher}
                    checked={formik.values.switch6}
                    onChange={formik.handleChange}
                    name="switch6"
                  />
                }
                label={
                  <Stack>
                    <Typography variant="subtitle2">Taşıma Süreçleri</Typography>
                    <Typography variant="caption" sx={{ color: '#002E6D' }}>
                      Şirket sürücüsü sözleşme bitiş tarihi e-posta bildirimi
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
            <Box marginTop="30px" paddingBottom="30px" display="flex" justifyContent="center">
              <Button fullWidth type="submit" variant="contained" color="primary" sx={buttonStyle}>
                Filtreleri Uygula
              </Button>
            </Box>
          </>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default Settings;

{
  /* <Switch sx={{
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#FFC400',
        '&:hover': {
            backgroundColor: '#FFC400'
        }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#FFC400',
        opacity: 1
    },
    '& .MuiSwitch-switchBase.Mui-checked:hover': {
        backgroundColor: 'transparent'
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#00245B'
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#D9D9D980',
        opacity: 1
    }
}} checked={formik.values.switch1} onChange={formik.handleChange} name="switch1"/> */
}
