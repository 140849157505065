import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Index } from './types';

const initialState = {
  email: '',
  phoneVerifyCode: '',
  pageStatus: '1',
  phone: ''
} as Index;

export const registerSlice = createSlice({
  name: 'registerSlice',
  initialState,
  reducers: {
    reset: () => initialState,
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhoneVerifyCode: (state, action: PayloadAction<string>) => {
      state.phoneVerifyCode = action.payload;
    },
    setPageStatus: (state, action: PayloadAction<string>) => {
      state.pageStatus = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    }
  }
});

export const { reset, setEmail, setPhoneVerifyCode, setPageStatus, setPhone } = registerSlice.actions;

export default registerSlice.reducer;
