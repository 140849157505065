// App.js
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import DashbordLayout from './layouts/dashboard/layouts-dashbord';
import LoginLayout from './layouts/auth';

import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { AuthStatus } from './page/Login/reducer/auth-slice';
import { ROUTES } from './const/routes/routers';
import Loader from './components/Loader';

const publicRouter = createBrowserRouter([
  {
    path: '/auth',
    element: <LoginLayout />,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    children: [
      {
        path: ROUTES.AUTHENTICATION.LOGIN.PATH,
        lazy: () => import('@/page/Login')
      },
      {
        path: ROUTES.AUTHENTICATION.REGISTER.PATH,
        lazy: () => import('@/page/Register')
      },
      {
        path: ROUTES.AUTHENTICATION.RESET_PASSWORD.PATH,
        lazy: () => import('@/page/PasswordReset')
      },
      {
        path: ROUTES.AUTHENTICATION.FORGOT_PASSWORD.PATH,
        lazy: () => import('@/page/ForgotPassword')
      }
    ]
  },
  {
    path: ROUTES.ERROR.PATH,
    lazy: () => import('@/page/Error')
  },
  {
    path: ROUTES.SHIPMENT_TRACK_LINK.PATH,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    lazy: () => import('@/page/ShipmentDetail/Index')
  },
  {
    path: '*',
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    element: <Navigate to="/auth/login" />
  }
]);

const privateRouter = createBrowserRouter([
  {
    path: '/',
    element: <DashbordLayout />,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    children: [
      {
        path: ROUTES.DASHBOARD.SHIPMENT_MANAGEMENT.PATH,
        lazy: () => import('@/page/ShipmentManagement')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.COMPANY_INFORMATION.PATH,
        lazy: () => import('@/page/CompanyInfo')
      },
      {
        path: ROUTES.DASHBOARD.SHIPMENT_MANAGEMENT.FTL_ADD.PATH,
        lazy: () => import('@/page/ShipmentManagement/add-ftl')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.COMPANY_INFORMATION.PATH,
        lazy: () => import('@/page/CompanyInfo')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.COMPANY_INFORMATION.COMPANY_INFORMATION_DELETE_ACCOUNT.PATH,
        lazy: () => import('@/page/CompanyInfo/company-delete')
      },
      {
        path: ROUTES.DASHBOARD.USER_MANAGEMENT.PATH,
        lazy: () => import('@/page/UserManagement/index')
      },
      {
        path: `${ROUTES.DASHBOARD.USER_MANAGEMENT.USER_DETAIL.PATH}/:userid/:projectid`,
        lazy: () => import('@/page/UserManagement/usermanagement-userdetail')
      },
      {
        path: ROUTES.DASHBOARD.USER_MANAGEMENT.USER_ADD.PATH,
        lazy: () => import('@/page/UserManagement/userManagement-userinvite')
      },
      {
        path: ROUTES.DASHBOARD.INVENTORY.DRIVER.PATH,
        lazy: () => import('@/page/DriverInventory/index')
      },
      {
        path: ROUTES.DASHBOARD.INVENTORY.DRIVER.DRIVER_ADD.PATH,
        lazy: () => import('@/page/DriverAdd/index')
      },
      {
        path: ROUTES.DASHBOARD.INVENTORY.DRIVER.DRIVER_DETAIL.PATH,
        lazy: () => import('@/page/DriverDetail')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PROJECT.PATH,
        lazy: () => import('@/page/ProjectManagement')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PROJECT.PROJECT_ADD.PATH,
        lazy: () => import('@/page/ProjectManagement/projectmanagement-add')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PROJECT.PROJECT_DETAIL.PATH}/:projectid`,
        lazy: () => import('@/page/ProjectManagement/projectmanagement-detail')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PROJECT.PROJECT_EDIT.PATH}/:projectid`,
        lazy: () => import('@/page/ProjectManagement/projectmanagement-edit')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.ADDRESS.PATH,
        lazy: () => import('@/page/AddressManagement')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.ADDRESS.ADDRESS_ADD.PATH}/:id`,
        lazy: () => import('@/page/AddressManagement/addressmanagement-add')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.ADDRESS.ADDRESS_EDIT.PATH}/:addressid/:projectid`,
        lazy: () => import('@/page/AddressManagement/addressmanagement-edit')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.ADDRESS.ADDRESS_DETAIL.PATH}/:addressid/:projectid`,
        lazy: () => import('@/page/AddressManagement/addressmanagement-detail')
      },
      {
        path: `${ROUTES.DASHBOARD.INVENTORY.VEHICLE.PATH}`,
        lazy: () => import('@/page/VehicleInventory')
      },
      {
        path: `${ROUTES.DASHBOARD.INVENTORY.VEHICLE.VEHICLE_ADD.PATH}`,
        lazy: () => import('@/page/VehicleInventory/vehicleinventory-add')
      },
      {
        path: `${ROUTES.DASHBOARD.INVENTORY.VEHICLE.VEHICLE_DETAIL.PATH}/:id`,
        lazy: () => import('@/page/VehicleInventory/vehicle-detail')
      },
      {
        path: ROUTES.DASHBOARD.COMPLETED_TRANSPORTATIONS.PATH,
        lazy: () => import('@/page/CompletedTransportations')
      }
    ]
  },
  {
    path: ROUTES.ERROR.PATH,
    element: <DashbordLayout />,
    children: [
      {
        path: ROUTES.ERROR.PATH,
        lazy: () => import('@/page/Error')
      }
    ]
  },
  {
    path: ROUTES.SHIPMENT_DETAIL.PATH,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    lazy: () => import('@/page/ShipmentDetail/Index')
  },
  {
    path: '*',
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    element: <Navigate to="/" />
  }
]);

function App() {
  const AUTH_STATUS = useSelector((state: RootState) => state.auth.AUTH_STATUS) as AuthStatus;
  const location = window.location.pathname;

  // Eğer URL shipment track link'i ise ve auth status loading değilse,
  // direkt olarak publicRouter'ı kullan
  const isTrackLinkPath = location.includes('/shipment/track/link/');

  if (AUTH_STATUS === AuthStatus.LOADING) {
    return <Loader />;
  }

  // Track link için özel kontrol
  if (isTrackLinkPath) {
    return <RouterProvider router={publicRouter} fallbackElement={<Loader />} />;
  }
  return (
    <RouterProvider
      router={AUTH_STATUS === AuthStatus.LOGGED_IN ? privateRouter : publicRouter}
      fallbackElement={<Loader />}
    />
  );
}

export default App;
